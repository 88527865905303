.cardtext {
    color: #fff;
    font-size: 64px;
}

.move {
    opacity: 1 !important;

}

@media (min-width:1024px)
{
.dontmove {
    opacity: 0.3 !important;
}
}