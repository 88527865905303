.text-break {
    word-wrap: break-word;
    word-break: break-word
}

.text-24 {
    font-size: 24px;
    text-align: center;

}

.large-text-main-color {
    color: #CF6245;
    font-size: 64px;
}

.background {
    background: #F9F7F1;
}

.orange-background {
    background: #E16C4D;

}

.move {
    background-color: aqua;
    align-self: stretch;
}