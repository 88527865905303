body{
  background-color: #F9F7F1;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.backColor{
  background-color: #F9F7F1;

}
.About{
  background-color: #F9F7F1;
}

.aboutArtizana{
  padding-top: 5vh;
  font-style: normal;
  font-size: calc( 6vw + 1.2rem);
  line-height: 80px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #CF6245;
  justify-content: center;
  font-family: Inter;
  font-weight: 800;
  letter-spacing: 0em;
  padding-bottom: 20px;
}

.AboutArt{
  margin-top: 8%;
  font-size: x-large;
  justify-content: center;
  align-items: center;
}

.paragraph1{
  min-width: 100px;
  display: flex;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: calc( 1.4vw + 0.7rem);;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: auto;
  padding-left: 29%;
  padding-right: 30%;
}

.Form{
  background-color: #2B2B2B;
}

.contactUs{
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: calc( 1vw + 0.8rem);
line-height: 44px;
display: flex;
padding-right: 12%;
align-items: center;
text-align: center;
justify-content: right;
color: #FFFFFF;
}

.formInputs{
  justify-content: right;
  width: calc(40% + 8vw);
  height: calc(3vh + 10px);
  margin-right: 12%;
  box-sizing: border-box;
  border: 1px solid #575555;
  border-radius: 8px;
  background-color: #2B2B2B;
  color: #FFFFFF;
  text-align: right;
}

.formMessage{
  justify-content: right;
  width: calc(40% + 8vw);
  height: calc(20vh + 4rem);
  margin-right: 12%;
  box-sizing: border-box;
  border: 1px solid #575555;
  border-radius: 8px;
  text-align:justify;
  color: #FFFFFF;
  background-color: #2B2B2B;
}

.mainButton{
  border-radius: 2vmin; 
  padding-inline: 6vmin;
  margin-right: 12%;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  text-align: center;
  justify-content: center;
}

.btn-primary {
  color: #fff;
  background-color: #E16C4D;
  background-color: cutom-color;
  border-color: #E16C4D;
  border-color: cutom-cor;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #ed6a46;
  border-color: #ed6a46; /*set the color you want here*/
}


.Princess{
  background-color: #E16C4D;
  margin-top: 5px;
  margin-bottom: 5px;
}
.color1{
  background-color: #2B2B2B;
}
.color2{
  background-color: #F9F7F1;
  height: 50%;
}
.padding-0{
  padding-right:0;
  padding-left:0;
  margin: 0;
}
.P-background{
  background-color: #E16C4D;
}
.princessName{
  font-weight: 600;
  font-size: calc(0.5rem + 2.1vw);
  display: flex;
  align-items: center;
  text-align: center;
  color: #F5F5F5;
  justify-content: center;
  padding-top: 2%;
}

.princessStatus{
  font-weight: 400;
  font-size: calc(0.3em + 1vw);
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #010101;
}

.princessParagraph{
  font-weight: 400;
  font-size: calc( 1.3vw + 0.4rem);;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #FFFFFF;
  padding-left: 15%;
  padding-right: 15%;
  padding-bottom: 3%;
}

.redShapeRightDiv{
  max-width: 17.5%;
}

.redShapeRight{
  margin-top: 10%;
  width: 70%;
  min-width: 45px;
  height: auto;
  background: #EB8266;
  border-radius: 2.23217px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.redShapeLeftDiv{
  max-width: 17.5%;
  display: flex;
  align-items: flex-end;
}

.redShapeLeft{
  margin-bottom: 10%;
  width: 70%;
  min-width: 45px;
  height: auto;
  background: #EB8266;
  border-radius: 2.23217px;
  transform: matrix(-1, 0, 0, 1, 0, 0);   
  transform: matrix(1, 0, 0, 1, 0, 0);
}

.redShapeRights{
  width: 127.05px;
  height: 133px;
  left: 91.05px;
  top: 10%;
  background: #EB8266;
  border-radius: 2.23217px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.redShapeLefts{
  margin-top: 0%;
  width: 127.05px;
  height: 133px;
  left: 91.05px;
  top: 10%;
  background: #EB8266;
  border-radius: 2.23217px;
  transform: matrix(-1, 0, 0, 1, 0, 0);   
  transform: matrix(1, 0, 0, 1, 0, 0);
}

.slidingImages{
  width: 100%;
  height: 20vh;
}

:root {
  --marquee-width: 100vw;
  --marquee-height: 20vh;
  --marquee-elements: 10;
  --marquee-elements-displayed: 5;
  --marquee-element-width: calc(var(--marquee-width) / var(--marquee-elements-displayed));
  --marquee-animation-duration: calc(var(--marquee-elements) * 3s);
}



.marquee {
  width: var(--marquee-width);
  height: var(--marquee-height);
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

.marquee-content {
  list-style: none;
  height: 100%;
  animation: scrolling var(--marquee-animation-duration) linear infinite;
  list-style-type: disc;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}
@keyframes scrolling {
  0% { transform: translateX(0); }
  100% { transform: translateX(calc(-1 * var(--marquee-element-width) * var(--marquee-elements))); }
}
.marquee-content li {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: var(--marquee-element-width);
  max-height: 100%;
  font-size: calc(var(--marquee-height)*3/4);
}

.marquee-content li img {
  width: 100%;
}

@media (max-width: 600px) {
  html { font-size: 12px; }
  :root {
    --marquee-width: 100vw;
    --marquee-height: 16vh;
    --marquee-elements-displayed: 3;
  }
  .marquee:before, .marquee:after { width: 5rem; }
}


.imagesSection{
  background-color: #F9F7F1;
  padding-top: 5%;
  padding-bottom: 5%;
}

.slidingImages{
  width: 125px;
  height: 97px;
  padding-left: 1%;
}
/*Contact Section CSS
----------------------------------------------------------*/

.Dark-Orange{
  background-color: #CF6245;

}
.White{
  color: white;

}
.right{
  float: right;
  
}
.padding11{
  padding-left: 11%;
}
.padding20{
  padding-left: 20%;

}
.padding{
  padding: 4px;
}
.margin-nav{
margin-left: 20px;
margin-right: 20px;


}
.font{
  font-size: xx-large;
}
/*End Of Contact
------------------------------------------------------*/


/*Vision Section CSS*/

div.vision{
  background-color: white;
  border: 2px solid #E16C4D;
  border-radius: 15px;
  margin-top: 5%;
  margin-bottom: 5%;
  padding: 2%;
  font-size: x-large;
  position: relative;

}

div.message{
  
  background-color: white;
  border: 2px solid #ABABAB;
  color: #ABABAB;
  border-radius: 15px;
  padding: 2%;
  font-size: x-large;
  position: relative;
  margin-bottom: 5%;

}

div.titleVisoin{
  color: #575555;
  font-size: xx-large;

}

div.titleMessage{
color: #575555;
font-size: xx-large;

}
div.titleMessage:hover{
  color: #E16C4D;
  
  }
  .Square:hover .titleMessage{
      color: #E16C4D;
  }
  
  .Square:hover .titleVisoin{
      color: #E16C4D;
  }
  .Square:hover img.hidden{
      visibility: visible;
  }
  .hidden{
      visibility: hidden;
  }
.shapes{
position: absolute;
left: 0%;
top: 10%;
}


.Square{
  transition: border-color 0.5s, box-shadow 0.5s;

}
.Square:hover {
  border-color: #E16C4D;
  box-shadow: 0px 0px 10px 2px rgba(237, 119, 28, 0.6);
  color: black;

}


/*End Of Vision
------------------------------------------------------*/


/*Market Section CSS*/


div.Souq{
  background-color: white;
  padding: 2%;
  font-size: large;
  position: relative;
}
div.place{
  background-color: white;
  border: 2px solid #E16C4D;
  border-radius: 15px;
  padding: 2%;
  font-size: large;
  position: relative;
  margin-top: 2%;
  margin-bottom: 2%;

}

div.WithYou{
  background-color: white;
  border: 2px solid #E16C4D;
  border-radius: 15px;
  padding: 2%;
  font-size: large;
  position: relative;
  margin-top: 2%;
  margin-bottom: 2%;

}

div.Suggestion{
  background-color: white;
  border: 2px solid #E16C4D;
  border-radius: 15px;
  padding: 2%;
  font-size: large;
  position: relative;
  margin-top: 2%;
  margin-bottom: 2%;

}
.SouqTitle{
  font-size: x-large;
  color: #E16C4D;
  margin-top: 2%;
  margin-bottom: 2%;

}
.title{
  font-size: x-large;
  
}

.shapes2{
  float: left;
  margin-left: 6%;
  height: 35px;
}
.innerMargin{
  margin-top: 6%;
  margin-bottom: 6%;
  margin-right: 3%;
}
.innerMargin2{
  padding-top: 6%;
  padding-bottom: 2%;
  margin-bottom: 2%;
}

.Suggestion:hover {
  border-color: #E16C4D;
  box-shadow: 0px 0px 10px 2px rgba(237, 119, 28, 0.6);

}
.place:hover{
  border-color: #E16C4D;
  box-shadow: 0px 0px 10px 2px rgba(237, 119, 28, 0.6);

}
.WithYou:hover{
  border-color: #E16C4D;
  box-shadow: 0px 0px 10px 2px rgba(237, 119, 28, 0.6);

}
.Suggestion,.place,.WithYou{
  transition: border-color 1s, box-shadow 0.5s;
}

/*End Of Market
------------------------------------------------------*/


/* Blog */

.blog{
}
.PaddingBlog{
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: xx-large;
}
.relative{
  position: relative;
  float: left;

}
.paddingRight{
  padding-right: 10%;
}
.MarginBottom{
  margin-bottom: 6%;
}
li{
  list-style: none;
  text-decoration: none;
}
.text-decoration{
  text-decoration: none;

}
