
.middle{
    align-items: center;
    justify-content: center;
}
.background{
    background-color: white;
}

  div.image {
    text-align: center;
  }
  .image{
    width: 100%;
  }
.margin{
    margin: 0.5%;
}
.margin:hover{
    background-color: #E16C4D;
    color: white;
}
.muted{
    opacity: 50%;
}
