.btn-primary {
    color: #fff;
    background-color: #E16C4D;
    background-color: cutom-color;
    border-color: #E16C4D;
    border-color: cutom-cor;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary {
    color: #fff;
    background-color: #ed6a46;
    border-color: #ed6a46; /*set the color you want here*/
}

.mainButton {
    border-radius: 2vmin; 
    padding-inline: 6vmin;

}